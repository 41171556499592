import React, { useCallback, useState } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { FaCaretDown } from 'react-icons/fa'
import type { BlogMenuItemI } from './CatMenu'

type DivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export interface CatMobileMenuPropsI extends DivElementProps {
  linkProps?: any
  menu: BlogMenuItemI[]
  getLinkTo: (item: BlogMenuItemI) => string
}

export default function CatMobileMenu(props: CatMobileMenuPropsI): JSX.Element {
  const { menu, getLinkTo, linkProps, className, ...divProps } = props

  const [subMenu, setSubMenu] = useState<string>('')
  const toggleSubMenu = useCallback((id: string) => {
    setSubMenu(value => (value === id ? '' : id))
  }, [])

  return (
    <div
      className="bg-white"
      style={{ boxShadow: '0px 3px 6px -3px #00000029' }}
    >
      <div
        className={classnames(className, 'container bg-white')}
        {...divProps}
      >
        <div className="mb-4">
          {menu.map(item => (
            <div
              key={item.id}
              className="border-b-4 border-transparent hover:border-lw-primary-dark py-4"
            >
              <a
                className="flex h-full items-center m-auto mx-1 text-sm font-bold text-black cursor-pointer"
                onClick={() => toggleSubMenu(item.id)}
              >
                <span>{item.label}</span>
                <FaCaretDown size={12} className="ml-4 text-lw-primary-dark" />
              </a>
              <Link
                key={item.id}
                {...linkProps}
                className={classnames(
                  'block min-h-12 w-56 py-3 px-6 text-sm font-bold text-black',
                  subMenu !== item.id && 'hidden'
                )}
                to={getLinkTo(item)}
              >
                {item.label}
              </Link>
              {item.items.map(subitem => (
                <Link
                  key={subitem.id}
                  {...linkProps}
                  className={classnames(
                    'block min-h-12 w-56 py-3 px-6 text-sm font-bold text-black',
                    subMenu !== item.id && 'hidden'
                  )}
                  to={getLinkTo(subitem)}
                >
                  {subitem.label}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
