import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import { FaCaretDown } from 'react-icons/fa'
import type { BlogMenuItemI } from './CatMenu'

type DivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export interface CatDesktopMenuPropsI extends DivElementProps {
  linkProps?: any
  menu: BlogMenuItemI[]
  getLinkTo: (item: BlogMenuItemI) => string
}

export default function CatDesktopMenu(
  props: CatDesktopMenuPropsI
): JSX.Element {
  const { menu, getLinkTo, linkProps, className, ...divProps } = props

  return (
    <div
      className="bg-white"
      style={{ boxShadow: '0px 3px 6px -3px #00000029' }}
    >
      <div
        className={classnames(
          className,
          'container flex flex-col bg-white mb-8'
        )}
        style={{ height: 110 }}
        {...divProps}
      >
        <h4 className="text-lw-primary-dark text-center text-3xl m-auto">
          Grow Your Business
        </h4>
        <div className="flex justify-around">
          {menu.map(item => (
            <div
              key={item.id}
              className="group relative h-10 border-b-4 border-transparent hover:border-lw-primary-dark pb-4"
            >
              <Link
                {...linkProps}
                to={getLinkTo(item)}
                className="flex h-full items-center m-auto mx-1 text-sm font-bold text-black"
              >
                <span>{item.label}</span>
                <FaCaretDown size={12} className="ml-4 text-lw-primary-dark" />
              </Link>
              <ul
                className="absolute hidden group-hover:block z-40 bg-white shadow"
                style={{ top: 'calc(100% + 4px)' }}
              >
                {item.items.map(subitem => (
                  <Link
                    key={subitem.id}
                    {...linkProps}
                    className="flex min-h-12 w-56 py-3 px-6 text-sm font-bold text-black hover:text-white hover:bg-lw-primary-dark"
                    to={getLinkTo(subitem)}
                  >
                    {subitem.label}
                  </Link>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
