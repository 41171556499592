import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import { GatsbyImage } from 'gatsby-plugin-image'
import swapCmsUrlWithCdn from '../../utils/swapCmsUrlWithCdn'

function CardPost({
  title,
  link,
  author,
  seo,
  date,
  featuredImage: featuredImageProp,
  categories,
}) {
  const featuredImage = swapCmsUrlWithCdn(featuredImageProp?.node?.mediaItemUrl, 420);

  return (
    <div className="relative max-w-l overflow-hidden border-solid border border-gray-300 bg-white">
      <div className="relative h-0" style={{ paddingBottom: `50.45%` }}>
        <Link to={link}>
        {featuredImage ? (
          <div
            className="absolute hidden md:block top-0 left-0 w-full h-full z-10"
            style={{ backgroundImage: `url(${featuredImage})`, backgroundSize: 'cover', backgroundPosition: '50% 50%' }}
          />
          ) : (
            <div className="bg-gray-700 absolute top-0 left-0 w-full h-full"></div>
          )}
        </Link>
      </div>

      <div className="px-6">
        <h2 className="font-semibold text-base md:text-xl leading-6 mt-4">
          <Link
            to={link}
            dangerouslySetInnerHTML={{ __html: title }}
            className="text-gray-900"
          />
        </h2>
      </div>

      <div className="px-6 pt-2">
        <p
          className="inline-block font-semibold text-xs mb-16"
          style={{ color: `#007199` }}
        >
          {date}
          {author?.node?.name && (
            <span>
              &nbsp; by &nbsp;
              <Link to={author?.node?.uri} className="underline">
                {author?.node?.name}
              </Link>
            </span>
          )}
        </p>

        <div
          className="absolute left-4 uppercase text-xs font-semibold"
          style={{ bottom: '1rem' }}
        >
          {categories.nodes[0] && (
            <Link to={categories.nodes[0].link}>
              {categories.nodes[0].name}
            </Link>
          )}
          {seo?.readingTime && (
            <p className="inline-block" style={{ color: '#007199' }}>
              &nbsp;|&nbsp;{seo.readingTime} min read
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

CardPost.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  author: PropTypes.object,
  seo: PropTypes.object,
  featuredImage: PropTypes.object,
  categories: PropTypes.object,
}

export default CardPost
