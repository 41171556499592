import React, { useMemo, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import CatDesktopMenu from './CatDesktopMenu';
import CatMobileMenu from './CatMobileMenu';
import classnames from 'classnames';

export interface BlogMenuItemI {
  id: string
  label: string
  url: string
  items: BlogMenuItemI[]
  isTop?: boolean
}

function useBlogMenu(): [BlogMenuItemI[], Record<string, BlogMenuItemI>] {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "blog-homepage-categories" }) {
        menuItems {
          nodes {
            id
            parentId
            label
            url
          }
        }
      }
    }
  `)

  const menuData: Record<string, BlogMenuItemI> = useMemo(() => {
    if (!wpMenu?.menuItems?.nodes) return {}

    const menuData: Record<string, BlogMenuItemI> = {}

    for (const item of wpMenu.menuItems.nodes) {
      const { id, parentId, label, url } = item

      menuData[id] = {
        id,
        label,
        url,
        items: [],
        isTop: !parentId,
      }

      if (menuData[parentId]?.items) {
        menuData[parentId].items.push(menuData[id])
      }
    }

    return menuData
  }, [wpMenu])

  const menu = useMemo(
    () => Object.values(menuData).filter(({ isTop }) => isTop),
    [menuData]
  )

  return [menu, menuData]
}

type DivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export interface BlogCatMenuPropsI extends DivElementProps {
  homeCategoryUrl?: string;
  linkProps?: any;
}

export default function BlogCatMenu(props: BlogCatMenuPropsI): JSX.Element {
  const { homeCategoryUrl, className, ...menuProps } = props;

  const [menu] = useBlogMenu();

  const getLinkTo = useCallback((item: BlogMenuItemI): string => {
    if (item.url === homeCategoryUrl) return '/blog/';
    return item.url;
  }, [homeCategoryUrl]);

  return <>
    <CatDesktopMenu
      className={classnames(className, 'hidden sm:flex')}
      menu={menu}
      getLinkTo={getLinkTo}
      {...menuProps}
    />
    <CatMobileMenu
      className={classnames(className, 'sm:hidden')}
      menu={menu}
      getLinkTo={getLinkTo}
      {...menuProps}
    />
  </>
}
