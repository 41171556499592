import { useLocation } from '@reach/router'
import { useMemo } from 'react';

/**
 * Scroll to an element based on location state then clear the value
 * @param element Element to scroll to
 * @param key location.state key to check and clear
 * @returns waiting on element
 */
export default function useScrollOnce(element: HTMLElement | undefined, key: string): boolean {
	const state = useLocation().state as Record<string, unknown> | null;

	const waiting = useMemo(() => {
		try {
			const value = Boolean(state?.[key]);
			if (!element || !state || !value) {
				return value;
			}

			// Remove state key
			delete state[key];
			history.replaceState(state, '');

			// Scroll to element
			element.scrollIntoView();
		} catch (e) {
			// Catch silently
		}

		// Allow element to remove
		return false;
	}, [element, key, state]);

	return waiting;
}
