import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import IconLeftArrow from '../../images/arrow-left.inline.svg'
import IconRightArrow from '../../images/arrow-right.inline.svg'

function Pagination({
  className,
  page,
  hasNextPage,
  hasPreviousPage,
  currentPage,
  pageCount,
  linkProps,
}) {
  let links = []
  // loop through total pages to build/display links
  for (let i = 1; i <= pageCount; i++) {
    let to = i === 1 ? page : `${page}page/${i}/`
    let hide = 'hidden' // hide links
    if (
      i === 1 || // always display first link
      i === pageCount || // always display last link
      i === currentPage || // always display current link
      i === currentPage + 1 || // display one link after the current
      i === currentPage + 2 || // display second link after the current
      i === currentPage - 1 || // display one link before the current
      i === currentPage - 2 // display second link before the current
    ) {
      hide = ''
    }

    // if last link & current page is equal or minor than 5 previous number from the last, diplay 3 dots before last link
    // checking 5 previous links since this pagination always display 5 in the middle between first and last
    if (i === pageCount && currentPage <= pageCount - 4) {
      links.push(
        <li
          key="pagination-left-dots"
          className="inline-block mx-2 py-2 px-3 text-blue-700"
        >
          ...
        </li>
      )
    }
    // build links
    links.push(
      <li
        key={`pagination-${i}`}
        className={`inline-block mx-2 ${hide}`}
        aria-hidden={hide ? 'true' : 'false'}
      >
        <Link
          {...linkProps}
          className={`py-2 px-3 ${
            i == currentPage
              ? ' bg-blue-400 text-white hover:text-white focus:text-white'
              : ' text-blue-700'
          }`}
          to={to}
        >
          {i}
        </Link>
      </li>
    )
    // if first link & current page is equal or bigger than next 5 links, display 3 dots after first link
    // checking 5 next links since this pagination always display 5 in the middle between first and last
    if (i === 1 && currentPage >= i + 4) {
      links.push(
        <li
          key="pagination-right-dots"
          className="inline-block mx-2 py-2 px-3 text-blue-700"
        >
          ...
        </li>
      )
    }
  }

  if (pageCount === 1) return null

  return (
    <nav className={className ? className : 'container text-center pt-10'}>
      <ul>
        {hasPreviousPage && currentPage !== 2 && (
          <li key="pagination-left" className="inline-block mx-2">
            <Link
              {...linkProps}
              to={`${page}page/${currentPage - 1}/`}
              className="flex py-2 px-3 text-blue-700"
              aria-label="previous page"
            >
              <IconLeftArrow />
            </Link>
          </li>
        )}
        {hasPreviousPage === true && currentPage === 2 && (
          <li key="pagination-left" className="inline-block mx-2">
            <Link
              {...linkProps}
              to={`${page}`}
              className="flex py-2 px-3 text-blue-700"
              aria-label="previous page"
            >
              <IconLeftArrow />
            </Link>
          </li>
        )}
        {links}
        {hasNextPage === true && (
          <li key="pagination-right" className="inline-block mx-2">
            <Link
              {...linkProps}
              to={`${page}page/${currentPage + 1}/`}
              className="flex py-2 px-3 text-blue-700"
              aria-label="next page"
            >
              <IconRightArrow />
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  page: PropTypes.string.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default Pagination
