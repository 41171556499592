import React, { RefCallback, useState } from "react";
import useScrollOnce from "../hooks/scrollOnce";

export interface ScrollOncePropsI {
	/** location.state key to check and clear */
	stateId: string;
	/** Vertical offset */
	offset?: number;
}

export default function ScrollOnce(props: ScrollOncePropsI): JSX.Element | null {
	const { stateId, offset = 0 } = props;
	const [element, setElement] = useState<HTMLDivElement | undefined>(undefined);
	const showElement = useScrollOnce(element, stateId)

	if (!showElement) {
		return null;
	}

	return (
		<div
			style={{
				marginTop: offset,
				marginBottom: -offset,
			}}
			ref={setElement as RefCallback<HTMLDivElement>}
		/>
	);
}
