import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

// Internal dependencies
import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import CardPost from '../components/cards/card-post'
import SubscribeHorizontalWidget from '../components/subscribe-widget/subscribe-horizontal-widget'
import SearchForm from '../components/search/search-form'
import HubspotForm from '../components/hubspot/HubspotForm'
import parseHtml from '../components/blocks/parser'
import Pagination from '../components/pagination/pagination'
import BlogCatMenu from '../components/blog/CatMenu'
import { AcfOverride } from '../components/blocks/AcfPosts/overrides'
import ScrollOnce from '../components/ScrollOnce'

import { BLOG_CONTENT_ID } from '../components/Link'

const BlogTemplatePage: React.FC<BlogTemplateProps> = props => {
  const { data } = props
  const { allWpPost, wpPage, category } = data

  const { nodes, pageInfo } = allWpPost || {}
  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo

  const post = {
    ...wpPage,
    slug: '/blog',
  }

  const [content, featuredArticles] = useMemo(() => {
    const parsedContent = parseHtml(wpPage.contentRaw)
    const contentChildren = React.Children.toArray(parsedContent)

    // Pull out featured articles block from the content and store
    const featuredIndex = contentChildren.findIndex(
      (child: any) =>
        child?.props?.blockAttrs?.name === 'acf/blog-featured-posts'
    )
    const featuredArticles =
      featuredIndex >= 0 ? contentChildren.splice(featuredIndex, 1) : null

    return [contentChildren, featuredArticles]
  }, [wpPage.contentRaw])

  return (
    <Layout mainClass="bg-gray-100">
      <Seo post={post} />
      <StructuredData pageType="webPage" post={post} />
      <SearchForm blog={true} />
      <div className="bg-lw-primary-faint pt-6">
        <div className="container">
          <h2
            className="text-lw-primary-dark mt-0 pb-2"
            style={{ fontSize: '22px' }}
          >
            Featured Articles
          </h2>
        </div>
        <AcfOverride list={{ className: 'pt-0 blog-featured-articles' }}>
          {featuredArticles}
        </AcfOverride>
      </div>
      <div className="bg-lw-primary-faint my-0 py-0">
        <div className="container pt-6 pb-10 text-center justify-center md:text-left md:flex flex-wrap xl:justify-between items-center">
          <p className="text-lg font-bold py-2" style={{ color: '#007199' }}>
            Want more news and updates like this straight to your inbox?
          </p>
          <HubspotForm
            standardFormID="32ecff0a-1868-49a2-bade-c6707e6b9720"
            cssClass="lw-hubspot-form-subscribe subscribe-header"
          />
        </div>
      </div>
      <ScrollOnce stateId={BLOG_CONTENT_ID} offset={-84} />
      <BlogCatMenu linkProps={{ state: { [BLOG_CONTENT_ID]: 1 } }} />
      {category && (
        <div className="container mb-8">
          <span
            className="inline-block px-4 py-2 bg-lw-primary-dark text-white w-full sm:w-auto"
            style={{ fontSize: '20px' }}
          >
            {category.name}
          </span>
        </div>
      )}
      <div className="container lg:flex md:flex">
        <div className="lg:w-full pb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-4 md:gap-4 lg:gap-8 lg:grid-cols-3">
            {nodes && nodes.map(node => <CardPost key={node.id} {...node} />)}
          </div>
          {category && (
            <Pagination
              page={category.uri}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              currentPage={currentPage}
              pageCount={pageCount}
              linkProps={{ state: { [BLOG_CONTENT_ID]: 1 } }}
            />
          )}
        </div>
      </div>
      <div className="entry">{content}</div>
      <SubscribeHorizontalWidget blog />
    </Layout>
  )
}

type BlogTemplateProps = {
  location: Record<string, unknown>
  data: {
    allWpPost: {
      nodes: Array<{
        id: string
        title: string
        link: string
        date: string
        excerpt: string
        lwContent: {
          isGutenberg: boolean
          content?: string
        }
        author: {
          node?: {
            name: string
            uri: string
          }
        }
        seo: {
          description?: string
          readingTime?: number
        }
        lwContentTextExcerpt: string
        featuredImage: {
          node?: {
            altText: string
            mediaItemUrl: string
            localFile: { childImageSharp: { fluid: FluidObject } }
          }
        }
        categories: {
          nodes?: Array<{
            name: string
            link: string
          }>
        }
      }>
      pageInfo: {
        hasNextPage: boolean
        hasPreviousPage: boolean
        currentPage: number
        pageCount: number
      }
    }
    category: {
      name: string
      description: string
      uri: string
      seo: any
    }
    wpPage: {
      title: string
      date: string
      modified: string
      uri: string
      seo: any
      contentRaw: string
    }
  }
  pageContext: {
    homeCategoryUrl: string
  }
}

export const query = graphql`
  query BlogArchivePage($id: String!, $offset: Int!, $perPage: Int!) {
    category: wpCategory(id: { eq: $id }) {
      name
      description
      uri
      seo {
        ...taxonomySeoFields
      }
    }
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $perPage
      skip: $offset
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        link
        date(formatString: "MMMM DD, YYYY")
        excerpt
        lwContent {
          isGutenberg
          content
        }
        author {
          node {
            name
            uri
          }
        }
        seo {
          metaDesc
          readingTime
        }
        lwContentTextExcerpt
        featuredImage {
          node {
            altText
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  layout: CONSTRAINED
                  width: 278
                  height: 141
                )
              }
            }
          }
        }
        categories {
          nodes {
            name
            link
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    wpPage(slug: { eq: "blog-template" }) {
      title
      date
      modified
      uri
      chatDepartment
      contentRaw
      seo {
        ...postSeoFields
      }
      ...ReusableBlockFragment
    }
    hero: file(relativePath: { eq: "blog/liquid-web-blog-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default BlogTemplatePage
